import React from "react"

import Success from "../../images/Sucessful.svg"
import { navigate } from "gatsby";
import { getToken } from "../../services/auth";
import styles from "./styles.module.css"
import { CLOUD_LOGIN_URL } from "../../services/API";

class SignupSuccess extends React.Component {
    constructor(props) {
        super(props);

        const token = getToken();

        const email = this.props.location && this.props.location.state && this.props.location.state.email;
        const loginLink = this.getLoginLink(email)

        this.state = {
            token,
            email,
            loginLink
        }
    }

    componentDidMount() {
    }

    navigateToExternal = (toLink) => {
        window.open(toLink,"_blank");
    }

    getLoginLink(email) {
        if (email) {
            var cloudLoginURL = CLOUD_LOGIN_URL || 'https://cloud.mprofit.in';
            return cloudLoginURL + '/sign-up?email='+encodeURIComponent(email);
        }

        return null;
    }

    render() {
        const successText = 'Login instructions sent to your email';

        const { isInvestor = true } = this.props;
        const email = this.state.email;
        console.log(this.props.location,'locationState');

        const buttonLink = this.state.loginLink;
        const buttonText = 'Continue to MProfit';

        return (
            <section className={[styles.payment_successfull_section,"plan-grey-background_","container-fluid"].join(" ")}>
                <div className={["container", "h-100"].join(" ")}>
                    <div className={["row", "h-100", "align-items-center"].join(" ")}>
                        <div className="v_center">
                            <div className={[styles.payment_successfull].join(" ")}>
                                <div className={["col", "text-center"].join(" ")}>
                                    <div>
                                        <span className={styles.successCheck}><i className={"material-icons"}>check</i></span>
                                    </div>
                                    <h2 className={["mt-5", "pb-3"].join(" ")}>{successText}</h2>
                                    <div className={["mt-5",styles.text2].join(" ")}>Thank you for signing up for MProfit! {isInvestor ? 'For questions' : 'If you would like a demo'}, please email us at <a href="mailto:MProfit Support<support@mprofit.in>">support@mprofit.in</a></div>
                                    {
                                        buttonLink && buttonText &&
                                        <div className="">
                                            <button className={["btn", "btn-primary", "rounded", "mt-4", styles.continueBtn].join(" ")} onClick={() => {this.navigateToExternal(buttonLink)}}>{buttonText}</button>
                                        </div>
                                    }
                                    <div className={["mt-5",styles.text3].join(" ")}>
                                        If you don't see the email in your inbox
                                        {
                                            email &&
                                            <span>{` (${email})`}</span>
                                        }
                                        , please check your spam folder
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SignupSuccess
